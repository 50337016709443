<div class="row">
  <div class="col-6">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Cari berdasarkan No Resi (tekan enter)" (keyup.enter)="getData()"
          aria-label="Cari..." [(ngModel)]="pgn.term" aria-describedby="btn-cari" />
        <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getFaktur()">
          <i class="fa fa-search">Cari berdasarkan No Faktur</i>
        </button>
        <button class="btn btn-outline-info" type="button" id="btn-filter" ngbTooltip="Filter Shipment Instruksi Pickup" (click)="getFilter()">
          <i class="fa fa-filter"></i>
        </button>
        <button class="btn btn-outline-warning" type="button" id="btn-refresh" (click)="getRefresh()">
          <i class="bx bx-reset"></i>
        </button>
      </div>
    </div>
    

</div>
<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table shipment-list-table table-nowrap align-text-top table-borderless">
            <thead style="position: sticky;top: 0">
              <tr>
                <th scope="col">No</th>
                <th scope="col" class="lebar">#</th>
                <th scope="col" class="lebar">No Resi</th>
                <th scope="col">Pickup</th>
                <th scope="col">Delivery</th>
                <th scope="col" class="lebar">Layanan</th>
                
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody  *ngFor="let n of dataList; let i = index">
                <tr>
                   <td style="vertical-align: middle;">
                 
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="!n.showDetail"
                          (click)="showDataDetail(i)" ngbTooltip="Lihat Detail">     {{ ((pgn.currentPage-1)*pgn.limit) + (i+1)}} <i
                              class="bx bx-chevrons-down"></i></button>
                      <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="n.showDetail"
                          (click)="showDataDetail(i)" ngbTooltip="Tutup Detail">     {{ ((pgn.currentPage-1)*pgn.limit) + (i+1)}} <i
                              class="bx bx-chevrons-up"></i></button>
                  </div>  
               
                  
                  </td>
                    <td style="vertical-align: middle;">
                          <img *ngIf="n?.moda_id=='A'" src="assets/images/air-freight.png" alt class="avatar-sm" />
                          <img *ngIf="n?.moda_id=='L'" src="assets/images/land-trucking.png" alt class="avatar-sm" />
                          <img *ngIf="n?.moda_id=='S'" src="assets/images/sea-freight.png" alt class="avatar-sm" />
                    </td>
                    <td style="vertical-align: middle;">
                      <span *ngIf="n.shipment_type_id==='CASH'" class="badge bg-success float-start" ><i class="bx bx-money"></i></span>
                      <span *ngIf="n.shipment_type_id==='COLLECT'" class="badge bg-success float-start" ><i class="bx bx-wallet"></i></span>
                      <p class="mb-0"><a href="javascript:void(0)" (click)="showDataDetail(i)"> {{n.shipment_awbm}}</a></p>
                       <p class="text-muted">{{n.shipment_date  | date : "dd-MM-yyyy" }}</p>
                       <p   *ngIf="n.shipment_type_id==='COLLECT'"  class="text-muted ">{{n.shipment_type_id}}</p>
                    </td>
                    <td>
                      <span><i class="bx bx-right-top-arrow-circle font-size-20 text-primary"></i>{{n.origin}}-{{n.originname}} </span>
                      <p class="text-black mb-0 text-wrap">{{n.partner_name}}</p>
                      <p class="text-muted mb-0 text-wrap">{{n.partner_address1}}</p>
                    </td>
                    <td>
                      <span><i class="bx bx-right-down-arrow-circle font-size-20 text-primary"></i>{{n.destination}}-{{n.destinationname}} </span>
                      <p class="text-black mb-0 text-wrap">{{n.receiver_name}}</p>
                      <p class="text-muted mb-0 text-wrap">{{n.receiver_address1}}</p>
                    </td>
                    <td>
                      <p class="mb-0 ">{{n.service_id}}</p>
                      <p class="text-black mb-0 text-wrap">{{n.weight|number}} {{n.uom_id}}</p>
                      <p *ngIf="n.moda_id=='A'">Udara</p>
                      <p *ngIf="n.moda_id=='L'">Darat</p>
                      <p *ngIf="n.moda_id=='S'">Laut</p>
                      <span class="badge badge-pill badge-soft-info font-size-11" [ngClass]=" { 'badge-soft-danger': n.last_status === 'PICKUP',
                      'badge-soft-warning':n.last_status === 'MANIFEST' }"> {{n?.last_status_remark}}</span>
                    </td>
                    <td class="text-center">
                      <div class="row">
                        <div class="col-12">
                          <div class="btn-group" role="group" aria-label="Basic example">


                             <div *ngIf="n.agen.city_code!==n.destination_detail.hub_id"><a href="/shipment/edit/{{n.shipment_awb}}" role="button" class="btn btn-sm btn-outline-secondary" *ngIf="!n.is_manifest"  ngbTooltip="Edit Shipment"><i class="bx bx-edit-alt"></i></a></div>
                             <div *ngIf="n.agen.city_code===n.destination_detail.hub_id"><a href="/shipment/edit/{{n.shipment_awb}}" role="button" class="btn btn-sm btn-outline-secondary" *ngIf="!n.is_delivery"  ngbTooltip="Edit Shipment"><i class="bx bx-edit-alt"></i></a></div>
                            <button type="button" class="btn btn-sm btn-outline-info"  ngbTooltip="Cetak Resi " (click)="openPrintModal('RESI_1',n.shipment_awb)"><i class="bx bx-printer"></i></button>
                            <button type="button" class="btn btn-sm btn-outline-success"  ngbTooltip="Cetak Resi " (click)="openPrintModal('RESI_2',n.shipment_awb)"><i class="bx bxs-printer"></i></button>
    
                            
                          </div>
                        </div>
                        <!-- <div class="col-12 mt-1 " *ngIf="!n.is_incoming_wh">
                            <button  type="button" class="btn btn-sm btn-primary"><i class="fi flaticon-delivery-package-with-umbrella-symbol f-left"></i>Konfirmasi Incoming WH</button>
                        </div> -->
                      </div>
                      
                      
                    </td>
                </tr>
                <tr *ngIf="n.showDetail">
                  <td colspan="9">
                    <div class="container pt-3 pb-3 bg-light">
                      <p class="h2" *ngIf="n.is_cash"><i class="fa fa-money text-primary"></i> Detail Shipment</p>
                      <hr/>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col">
                              <p class="h6">No Resi</p>
                              <p class="text-muted">{{n.shipment_awbm}}</p>
                            </div>
                            <div class="col">
                              <p class="h6">Tanggal Pengiriman</p>
                              <p class="text-muted">{{n.shipment_date  | date : "dd-MM-yyyy" }}</p>
                            </div>
                            <div class="col">
                              <p class="h6">Referensi</p>
                              <p class="text-muted">{{n.shipment_do}}</p>
                            </div>
                            <div class="col">
                              <p class="h6">Moda</p>
                              <p class="text-muted"> <i class ="fi text-primary" [ngClass]="{'flaticon-airplane-frontal-view': n.moda_id === 'A', 'flaticon-delivery-truck' :  n.moda_id === 'L' ,'flaticon-sea-ship': n.moda_id === 'S'}"> </i> {{n.moda_id=='A'?'AIR':n.moda_id=='L'?'LAND':'SEA' }}</p>
                            </div>
                            <div class="col">
                              <p class="h6">Service</p>
                              <p class="text-muted">{{n.service_id}}</p>
                            </div>
                            <div class="col">
                              <p class="h6">UOM</p>
                              <p class="text-muted">{{n.weight}} - {{n.uom_id}}</p>
                            </div>
                          </div>
                          <hr/>
                          <div class="row">
                            <div class="col">
                              <p class="h6">Pengirim</p>
                                <div class="row text-muted">
                                  <div class="col-3">Nama</div>
                                  <div class="col-9 text-wrap">{{n.partner_id}}-{{n.partner_name}}</div>
                                  <div class="col-3">Alamat</div>
                                  <div class="col-9 text-wrap">{{n.partner_address1}}</div>
                                  <div class="col-3">Phone</div>
                                  <div class="col-9">{{n.partner_phone1}}</div>
                                  <div class="col-3">Asal</div>
                                  <div class="col-9">{{n.origin}} - {{n.originname}}</div>
                                </div>
                            </div>
                            <div class="col">
                              <p class="h6">Penerima</p>
                                <div class="row text-muted">
                                  <div class="col-3">Nama</div>
                                  <div class="col-9">{{n.receiver_name}}</div>
                                  <div class="col-3">Alamat</div>
                                  <div class="col-9 text-wrap">{{n.receiver_address1}}</div>
                                  <div class="col-3">Phone</div>
                                  <div class="col-9">{{n.receiver_phone1}}</div>
                                  <div class="col-3">Tujuan</div>
                                  <div class="col-9">{{n.destination}} - {{n.destinationname}}</div>
                                </div>
                            </div>
                        </div>
                          <hr/>
                          <div class="row">
                            <div class="col">
                                <div class="row text-muted">
                                  <div class="col-3">Pickup By</div>
                                  <div class="col-9">{{n.pickup_by}}</div>
                                  <div class="col-3">Sales By</div>
                                  <div class="col-9">{{n.sales_by}}</div>
                                  <div class="col-3">Intruksi</div>
                                  <div class="col-9">{{n.specialinst}}</div>
                                  <div class="col-3">Item Description</div>
                                  <div class="col-9 text-wrap">{{n.itemdesc}}</div>
                                </div>
                            </div>
                          
                          </div>
                        </div>
                        <div class="col-md-4">
                          <p class="h6">Status</p>
                          <div class="">
                            <ul class="verti-timeline list-unstyled">
                              <li class="event-list" *ngFor="let x of n.history;">
                                <div class="event-timeline-dot">
                                  <i class="bx bx-right-arrow-circle bx-fade-right"></i>
                                </div>
                                <div class="media">
                                  <div class="me-3">
                                    <i class="{{x.status.icon}} h2 text-primary"></i>
                                  </div>
                                  <div class="media-body">
                                    <div>
                                      <h6 class="mb-0">{{x.history_date | date : "dd-MM-yyyy HH:mm"}}</h6>
                                      <p class="text-wrap mb-0">{{x.status_name}}</p>
                                      <p class="text-muted text-wrap mb-0">{{x.description}}</p>
                                      <div class="zoom-gallery d-flex">
                                        <div>
                                          <img [src]="imgUrl+x.pic1" alt="pic1"  width="50" *ngIf="x.pic1" (click)="openImage(x)">
                                          <img [src]="imgUrl+x.pic2" alt="pic2"    width="50" *ngIf="x.pic2"  (click)="openImage(x)">
                                          <img [src]="imgUrl+x.pic3" alt="pic3"  width="50" *ngIf="x.pic3"  (click)="openImage(x)">

                                            
                                        </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                               
                            </ul>
                          </div>
                          
                          
                        </div>
                      
                      </div>
                      
                     
                   
                      
                  
                  </div>
                  </td>
                </tr>
              
            </tbody>
          </table>
        </div>
    </div>
    <div class="col-lg-12">
      <ngb-pagination  class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage" [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage" [rotate]="true"></ngb-pagination>
    </div>
  </div>
