import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Toaster } from "ngx-toast-notifications";
import { BehaviorSubject, Subscription } from "rxjs";
import { ModalContactComponent } from "src/app/modals/modal-contact/modal-contact.component";
import { ModalDestinationComponent } from "src/app/modals/modal-destination/modal-destination.component";
import { ModalQuestionsComponent } from "src/app/modals/modal-questions/modal-questions.component";
import { ModalSubcontactComponent } from "src/app/modals/modal-subcontact/modal-subcontact.component";
import { ApiService } from "src/app/services/api.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GlobalConstants } from "src/app/services/global";
import { SaInvoiceService } from "src/app/services/sa/sa-invoice.service";
@Component({
  selector: "app-shipment-entry",
  templateUrl: "./shipment-entry.component.html",
  styleUrls: ["./shipment-entry.component.scss"],
})
export class ShipmentEntryComponent implements OnInit {
  @Input() isEdit: boolean = false;
  @Input() shpNo: string = null;
  isAutoNumber: boolean = true;
  isIncomingWh: boolean = false;
  isInsurance: boolean = false;
  loadingSave: boolean = false;
  serviceData: any;
  uomData: any;
  modaData: any;
  sjNo: string;
  captionButton: string = "Simpan";
  detailRef: any = [];
  detailItem: any = [];
  companyData: any = {};
  userValue: any;
  private DOShipmentS: Subscription;
  constructor(
    private toast: Toaster,
    private apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private modal: NgbModal,
    private authService: AuthenticationService,
    private saInvoice:SaInvoiceService
  ) {}
  DO: any = {
    shipment_awbm: null,
    shipment_date: new Date(),
    moda_id: "A",
    charge: 0,
    pickup_charge: 0,
    packing_charge: 0,
    sub_total: 0,
    tax_percent: 1,
    tax_amount: 0,
    sub_total_tax: 0,
    insurance_price: 0,
    insurance_charge: 0,
    qty: 1,
    weight_actual: 1,
    weight_invoice: 1,
    uom_id: "KG",
    weight_vol: 1,
    weight: 1,
    total: 0,
    shipment_type_id: "CREDIT",
    is_from_pickup: true,
    is_incoming_wh:false
  };
  ngOnInit() {
    this.userValue = this.authService.currentUserValue;
    this.getDefaultData();
    if (this.isEdit === true) {
      console.log(this.shpNo);
      this.getShipmentId();
    }
    this.resetData();
  }
  ngOnDestroy(): void {}

  onChangeDate(newDate, field: string) {
    console.log(new Date(newDate));
    this.DO.shipment_date=new Date(newDate);
  }
  onChangeModa(newObj) {
    console.log(newObj);
    // ... do other stuff here ...
  }
  onChangePickupBy(newObj) {
    console.log(newObj);
  }
  onChangePickupUom(newObj) {
    console.log(newObj);
    this.DO.uom_id=newObj
  }
  openContact(data: string = "CUSTOMER") {
    const modalRef = this.modal.open(ModalContactComponent, {
      scrollable: true,
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.title = data;
    modalRef.result.then(
      (r) => {
        if (r && r.data) {
          console.log(r);
          if (data == "CUSTOMER") {
            this.DO["partner_id"] = r.data.contact_id;
            this.DO["partner_name"] = r.data.name;
            this.DO["partner_pic"] = r.data.reff_contact_name;
            this.DO["partner_phone1"] = r.data.phone1;
            this.DO["partner_address1"] = r.data.address1;
            if (!this.DO["origin"]) {
              this.DO["origin"] = r.data.city_code;
              this.DO["originname"] = r.data.city_name;
            }
          }
          if (data == "INTERNAL") {
            this.DO["sales_id"] = r.data.contact_id;
            this.DO["sales_by"] = r.data.name;
          }
          if (data == "DRIVER") {
            this.DO["pickup_id"] = r.data.contact_id;
            this.DO["pickup_by"] = r.data.name;
          }
        }
      },
      (x) => {}
    );
  }
  openModalSubContact() {
    const modalRef = this.modal.open(ModalSubcontactComponent, {
      scrollable: true,
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.customerId = this.DO.partner_id;
    modalRef.result.then(
      (r) => {
        if (r) {
          this.DO.receiver_id = r.data.contact_sub_id;
          this.DO.receiver_name = r.data.name;
          this.DO.receiver_phone1 = r.data.phone1;
          this.DO.receiver_address1 = r.data.address1;
          this.DO.destination = r.data.city_code;
          this.DO.destinationname = r.data.city_name;
        }
      },
      (x) => {}
    );
  }
  openModalDest(data: string = "ORIGIN") {
    const modalRef = this.modal.open(ModalDestinationComponent, {
      scrollable: true,
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (r) => {
        if (r) {
          if (r.type === "DESTINATION") {
            this.DO["destination"] = r.data.dest_id;
            this.DO["destinationname"] = r.data.dest_name;
          } else {
            this.DO["origin"] = r.data.dest_id;
            this.DO["originname"] = r.data.dest_name;
          }
        }
      },
      (x) => {}
    );
  }
  onChangeVol(dt) {
    console.log(dt);
    let we_a: number = this.DO.weight_actual
      ? parseFloat(this.DO.weight_actual)
      : 1;
    let we_v: number = this.DO.weight_vol ? parseFloat(this.DO.weight_vol) : 1;
    if (we_a > we_v) {
      this.DO.weight = we_a;
      this.DO.weight_invoice = we_a;
    } else {
      this.DO.weight = we_v;
      this.DO.weight_invoice = we_v;
    }

  }
  onChangePrice(data) {
    const x = this.saInvoice.hitungHarga(this.DO);
    this.DO.sub_total = x.sub_total??0;
    this.DO.tax_amount = x.tax_amount.toFixed(0)??0;
    console.log(this.DO.tax_amount)
    this.DO.sub_total_tax = x.sub_total_tax??0;
    this.DO.total = x.total??0;
  }
  async getDefaultData() {
    const res: any = await this.apiService.getGlobalData();
    if (res.success) {
      this.serviceData = res.data.service;
      this.uomData = res.data.uom;
      this.modaData = res.data.moda;
    } else {
      this.toast.open({ type: "danger", text: "Error" });
    }
  }
  async getShipmentId() {
    const res: any = await this.apiService.getShipmentByShipmentAwb(this.shpNo);
    console.log(res);
    this.captionButton = "Update";
    if (res.success) {
      let x = res.data;
      this.isIncomingWh = x.is_incoming_wh;
      this.DO.shipment_awb = x.shipment_awb;
      this.DO.shipment_awbm = x.shipment_awbm;
      this.DO.shipment_date = new Date(x.shipment_date);
      this.DO.shipment_do = x.shipment_do;
      this.DO.shipment_type_id = x.shipment_type_id||'CREDIT';
      this.DO.moda_id = x.moda_id;
      this.DO.service_id = x.service_id;
      this.DO.pickup_id = x.pickup_id;
      this.DO.pickup_by = x.pickup_by;
      this.DO.sales_id = x.sales_id;
      this.DO.sales_by = x.sales_by;
      this.DO.partner_id = x.partner_id;
      this.DO.partner_name = x.partner_name;
      this.DO.partner_pic = x.partner_pic;
      this.DO.partner_phone1 = x.partner_phone1;
      this.DO.partner_address1 = x.partner_address1;
      this.DO.origin = x.origin;
      this.DO.originname = x.originname;
      this.DO.receiver_id = x.receiver_id;
      this.DO.receiver_name = x.receiver_name;
      this.DO.receiver_pic = x.receiver_pic;
      this.DO.receiver_address1 = x.receiver_address1;

      this.DO.destination = x.destination;
      this.DO.destinationname = x.destinationname;
      this.DO.qty = x.qty;
      this.DO.uom_id = x.uom_id;
      this.DO.weight_vol = x.weight_vol;
      this.DO.weight_invoice = x.weight_invoice;
      this.DO.specialinst = x.specialinst;
      this.DO.itemdesc = x.itemdesc;
      this.DO.charge=x.charge;
      this.DO.pickup_charge=x.pickup_charge;
      this.DO.packing_charge=x.packing_charge;
      this.DO.tax_amount=x.tax_amount;
      this.DO.insurance_price=x.insurance_value;
      this.DO.insurance_charge=x.insurance;
      this.DO.tax_percent=x.tax_percent;
      this.DO.total=x.total;
      this.isInsurance=x.is_insurance;


      this.detailRef = x.detail_reff;
      


    } else {
      this.toast.open({ type: "danger", text: "Error" });
    }
  }
  resetData() {
    return (this.DO = {
      shipment_awbm: null,
      shipment_date: new Date(),
      moda_id: "A",
      charge: 0,
      pickup_charge: 0,
      packing_charge: 0,
      sub_total: 0,
      tax_percent: 1.1,
      tax_amount: 0,
      sub_total_tax: 0,
      insurance_price: 0,
      insurance_charge: 0,
      qty: 1,
      weight_actual: 1,
      uom_id: "KG",
      weight_vol: 1,
      weight: 1,
      total: 0,
      shipment_type_id: "CREDIT",
      is_from_pickup: true,
      isIncomingWh: false,
      origin: this.userValue.city.dest_id,
      originname: this.userValue.city.dest_name,
    });
  }
  validasiShipment() {
    return this.simpanTanya();
  }
  simpanTanya() {
    const modalRef = this.modal.open(ModalQuestionsComponent, {
      size: "sm",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Simpan";
    modalRef.componentInstance.content = "Simpan pengiriman ini ?";
    modalRef.result.then(
      (result) => {
        if (result) {
          return this.simpanShipment();
        } else {
        }
      },
      (reason) => {
        console.log("res", reason);
      }
    );
  }
  async simpanShipment() {
    this.loadingSave = true;
    this.captionButton = "Tunggu...";
    this.DO.is_auto = this.isAutoNumber;
    this.DO.detailReff = JSON.stringify(this.detailRef);
    this.DO.detailItem = JSON.stringify(this.detailItem);
    this.isIncomingWh
      ? (this.DO.is_incoming_wh = true)
      : (this.DO.is_incoming_wh = false);

      this.isIncomingWh
      ? (this.DO.is_from_pickup =false)
      : (this.DO.is_from_pickup = true);
    let prm = {
      ...this.DO,
    };

    
      prm.is_insurance=this.isInsurance
    console.log('--->>>>>>>>>>>',this.isIncomingWh);    
    console.log(prm);

    if (this.isEdit == true) {
      console.log('edit,',prm);
      const res: any = await this.apiService.SHIPMENT.updateShipment(prm.shipment_awb,prm);
      console.log(res);
      if (res.success) {
        this.toast.open({
          type: "success",
          text: "Shipment berhasil di update",
        });
        this.loadingSave = false;
        this.captionButton = "Simpan";
        return this.router.navigate(["/shipment/pickup"]);
      } else {
        this.loadingSave = false;
        this.captionButton = "Simpan";
        return this.toast.open({ type: "danger", text: Array.isArray(res.err) ? res.err[0].msg : res.err });
      }
      
    } else {
      const res: any = await this.apiService.SHIPMENT.saveShipment(prm);
      console.log(res);
      if (res.success) {
        this.toast.open({
          type: "success",
          text: "Shipment berhasil di simpan",
        });
        this.loadingSave = false;
        this.captionButton = "Simpan";
        return this.resetData;
      } else {
        this.loadingSave = false;
        this.captionButton = "Simpan";
        return this.toast.open({ type: "danger", text: Array.isArray(res.err) ? res.err[0].msg : res.err });
      }
    }
  }
  detailSuratJalan = {
    cari: async () => {
      
      if (GlobalConstants.cariArrayData(this.sjNo, this.detailRef, "reff_no")) {
        this.toast.open({ type: "danger", text: "Nomor ini sudah di masukan" });
      } else {
        const res: any  = await this.apiService.getQueryShipmentFaktur(this.sjNo);
        console.log('-->>>> res',res);
        if(res && res.success==true && res.data){
          this.toast.open({ type: "danger", text: `Nomor ini sudah di masukan di ${res.data.shipment_awbm}` });
        
        }else{
          this.detailRef.push({ reff_no: this.sjNo });
        }
        
      }
      this.sjNo = null;
    },
    delete: (i) => {
      console.log(i);
      this.detailRef.splice(i, 1);
    },
  };
}
