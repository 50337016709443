import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgApexchartsModule } from "ng-apexcharts";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgxMaskModule } from "ngx-mask";
import { UIModule } from "src/app/shared/ui/ui.module";
import { SelectCompanyComponent } from "./select-company/select-company.component";
import { ModalValidasiComponent } from "./modal-validasi/modal-validasi.component";

import { ValidasiComponent } from "./validasi/validasi.component";
import { ModalHargaComponent } from "./modal-harga/modal-harga.component";
import { WidgetModule } from "src/app/shared/widget/widget.module";
import { ModalSaContactComponent } from "./modal-sa-contact/modal-sa-contact.component";
import { ModalSaShipmentComponent } from "./modal-sa-shipment/modal-sa-shipment.component";
import { SaShipmentComponent } from "./sa-shipment/sa-shipment.component";
import { ModalSaBankComponent } from "./modal-sa-bank/modal-sa-bank.component";
import { SaInvoiceListComponent } from "./sa-invoice-list/sa-invoice-list.component";
import { SaShipmentMainComponent } from "./sa-shipment-main/sa-shipment-main.component";
import { SimplebarAngularModule } from "simplebar-angular";
import { ModalSaInvoiceComponent } from './modal-sa-invoice/modal-sa-invoice.component';
import { ModalSaSewagudangComponent } from './modal-sa-sewagudang/modal-sa-sewagudang.component';
import { SaInvoiceListSewagudangComponent } from './sa-invoice-list-sewagudang/sa-invoice-list-sewagudang.component';
@NgModule({
  declarations: [
    SelectCompanyComponent,
    ModalValidasiComponent,
    ValidasiComponent,
    ModalHargaComponent,
    ModalSaContactComponent,
    ModalSaShipmentComponent,
    SaShipmentComponent,
    ModalSaBankComponent,
    SaInvoiceListComponent,
    SaShipmentMainComponent,
    ModalSaInvoiceComponent,
    ModalSaSewagudangComponent,
    SaInvoiceListSewagudangComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    FormsModule,
    WidgetModule,
    UIModule,
    AutocompleteLibModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxMaskModule,
    SimplebarAngularModule
  ],
  exports: [
    SelectCompanyComponent,
    ModalValidasiComponent,
    ValidasiComponent,
    ModalHargaComponent,
    ModalSaContactComponent,
    ModalSaShipmentComponent,
    SaShipmentComponent,
    ModalSaBankComponent,
    SaInvoiceListComponent,
    SaShipmentMainComponent,
    SaInvoiceListSewagudangComponent,
    ModalSaInvoiceComponent
  ],
})
export class ComponentSaModule {}
