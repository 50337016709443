import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};
 
 
@Injectable({
  providedIn: 'root'
})
export class SaCompanyService {

  constructor(private httpClient: HttpClient) { }

  public getBranchList(){
    return this.httpClient
    .get(`${environment.apiUrl}/sa/company/branch`)
    .toPromise();
  }

  public getBranch(companyId:string){
    const x = this.httpClient
    .get(`${environment.apiUrl}/sa/company/branch`)
    .toPromise();
    console.log(x);
  }

  public getBankList(companyId:string){
    return this.httpClient
    .get(`${environment.apiUrl}/sa/company/bank/${companyId}`)
    .toPromise();

  }
  public saveBankList(params:any,companyId:string){
    return this.httpClient
    .post<any>(
      `${environment.apiUrl}/sa/company/bank/${companyId}`,
      params,
      httpOptions
    )
    .toPromise();
  }
}
