import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { ModalContactComponent } from '../modal-contact/modal-contact.component';
const globalDate = new Date();
@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss']
})
export class ModalFilterComponent implements OnInit {
  @Input() fromParent;
  serviceData:any=[];
  uomData:any=[];
  modaData:any=[];
  statusData:any=[];
  filter:any={
    status:false
  }
  filterData:any={};
  startDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1);
  endDate: any = new Date(
    globalDate.getFullYear(),
    globalDate.getMonth() + 1,
    0
  );

  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster,
    private modal: NgbModal,
  ) {
    this.filter.status=false;

   }
   onChangeFilter(event,status) {
    console.log(event);
    console.log(status);
    console.log(this.filter)
    // ... do other stuff here ...
  }
  async ngOnInit()  {
    await this.getDefaultData();
    await this.getStatus();
    console.log(this.statusData);
  }

  async getDefaultData() {
    const res: any = await this.apiService.getGlobalData();
    if (res.success) {
      this.serviceData = res.data.service;
      this.uomData = res.data.uom;
      this.modaData = res.data.moda;
    } else {
      this.toast.open({ type: "danger", text: "Error" });
    }
  }

  openContact(data: string = "CUSTOMER") {
    const modalRef = this.modal.open(ModalContactComponent, {
      scrollable: true,
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.title = data;
    modalRef.result.then(
      (r) => {
        if (r && r.data) {
          console.log(r);
          if (data == "CUSTOMER") {
            this.filterData.partner_id=r.data.contact_id
            this.filterData.partner_name = r.data.name;
          }
          if (data == "INTERNAL") {
            
          }
          if (data == "DRIVER") {
        
          }
        }
      },
      (x) => {}
    );
  }

  async getStatus (){
    const res:any = await this.apiService.getStatusMain();
    if (res.success) {
      this.statusData = res.data;
    
    } else {
      this.toast.open({ type: "danger", text: "Error" });
    }
  }

  getDate(event) {
    console.log(event);
    this.startDate = event.fromDate;
    this.endDate = event.endDate;
  }

  getFilter(){
    console.log(this.filter);
    
    let filterX = {};
    this.filter.partner && this.filterData.partner_id?Object.assign(filterX,{partner_id:this.filterData.partner_id}):null;
    this.filter.status && this.filterData.status_id?Object.assign(filterX,{last_status:this.filterData.status_id}):null;
    if (this.filter.byPeriod){
      this.filterData.startDate=this.startDate;
      this.filterData.endDate=this.endDate;
    }
    console.log(this.filterData);
    console.log(filterX);
    this.activeModal.close({filter:filterX,startDate:this.filterData.startDate,endDate:this.filterData.endDate});
  }


  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
