<form 
    class="form-data"
    [formGroup]="validateForm"
    (ngSubmit)="simpanHarga()"
>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 mb-4">
              <div class="button-items">
                <button type="button" class="btn btn-primary" (click)="resetForm()">
                    <i class="bx bx-money font-size-16 align-middle me-2"></i> Harga Baru</button>
              </div>
            </div>
            <div class="col-md-4">
                <div class="mb-1 row">
                    <label for="origin" class="col-md-4 col-form-label">Asal</label>
                    <div class="col-md-8">
                        <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="origin"
                              name="origin"
                              formControlName="origin"
                              aria-describedby="origin"/>
                              <button class="btn btn-primary" type="button"
                              (click)="openModalDest('ORIGIN')">
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="originname" class="col-md-4 col-form-label"></label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            class="form-control"
                            id="originname"
                            name="originname"
                            formControlName="originname"
                            placeholder="Nama Kota Asal"/>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="lead_time_from" class="col-md-4 col-form-label">Lead Time</label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            class="form-control"
                            id="lead_time_from"
                            name="lead_time_from"
                            formControlName="lead_time_from"
                            placeholder="Lead Time "/>
                    </div>                    
                  </div>
            </div>
            <div class="col-md-4">
                <div class="mb-1 row">
                    <label for="destination" class="col-md-4 col-form-label">Tujuan</label>
                    <div class="col-md-8">
                        <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="destination"
                              formControlName="destination"
                              aria-describedby="destination"/><button
                              class="btn btn-primary"
                              type="button"
                              (click)="openModalDest('DESTINATION')">
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="destinationname" class="col-md-4 col-form-label"></label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            class="form-control"
                            id="destinationname"
                            name="destinationname"
                            formControlName="destinationname"
                            placeholder="Nama Kota Tujuan"/>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="lead_time_to" class="col-md-4 col-form-label">hari s d </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            class="form-control"
                            id="lead_time_to"
                            name="lead_time_to"
                            formControlName="lead_time_to"
                            placeholder="Sampai dengan "/>
                    </div>                    
                  </div>
            </div>
            <div class="col-md-4">
                <div class="mb-1 row">
                    <label for="moda" class="col-md-4 col-form-label">Moda</label>
                    <div class="col-md-8">
                        <select class="form-select" formControlName="moda_id" id="moda_id">
                            <option *ngFor="let x of modaData; let i = index" [value]="modaData[i].moda_id">
                              {{modaData[i].moda_name}}
                            </option>
                          </select>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="moda" class="col-md-4 col-form-label">Service</label>
                    <div class="col-md-8">
                        <select class="form-select" formControlName="service_id" id="service_id">
                            <option *ngFor="let x of serviceData; let i = index" [value]="serviceData[i].service_id">
                              {{serviceData[i].service_desc}}
                            </option>
                          </select>
                    </div>                    
                  </div>
                  <div class="mb-1 row">
                    <label for="moda" class="col-md-4 col-form-label">UOM</label>
                    <div class="col-md-8">
                        <select class="form-select" formControlName="uom_id" id="uom_id">
                            <option *ngFor="let x of uomData; let i = index" [value]="uomData[i].uom_id">
                              {{uomData[i].uom_desc}}
                            </option>
                          </select>
                    </div>                    
                  </div>
            </div>
          </div> 
    </div>
    <hr/>
    <div class="card-body"  *ngIf="isiDataSelanjutnya">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-1 row">
            <label for="price_from" class="col-md-4 col-form-label">Dari {{validateForm.get('uom_id').value}}</label>
            <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      id="price_from"
                      name="price_from"
                      formControlName="price_from"
                      aria-describedby="price_from"/>
            </div>                    
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-1 row">
            <label for="price_to" class="col-md-4 col-form-label">s/d</label>
            <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      id="price_to"
                      name="price_to"
                      formControlName="price_to"
                      aria-describedby="price_to"/>
            </div>                    
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-1 row">
            <label for="type_price" class="col-md-4 col-form-label">Type Harga</label>
            <div class="col-md-8">
              <select class="form-select" formControlName="type_price" id="type_price">
                <option *ngFor="let x of typeHarga; let i = index" [value]="typeHarga[i]">
                  {{typeHarga[i]}}
                </option>
              </select>
            </div>                    
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-1 row">
            <label for="price_1" class="col-md-4 col-form-label">Harga</label>
            <div class="col-md-8">
              <input type="text" class="form-control text-end"   id="price_1" name="price_1" formControlName="price_1" mask="separator.0" thousandSeparator="." data-reverse="true"  aria-describedby="price_1" />
            </div>                    
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="isiDataSelanjutnya">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead class="bg-primary">
            <tr>
              <th class="text-light">Mulai Dari</th>
              <th class="text-light">Sampai Dengan </th>
              <th class="text-light">Type Harga('Fixed/Flat')</th>
              <th class="text-light">Harga Per </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let n of hargaData; let i = index">
              <td>
                {{n.price_from}} 
              </td>
              <td>
                {{n.price_to|number}} 
              </td>
              <td>
                {{n.type_price}} 
              </td>
              <td>
                {{n.price_1|number}} 
              </td>
              <td>
                <div class="btn-group">
                  <button type="button" class="btn btn-info btn-rounded btn-condensed btn-sm" (click)="fillFormData(n)">
                  <span class="fa fa-edit"></span>
              </button> 
                  <button class="btn btn-danger btn-rounded btn-condensed btn-sm"
                  ng-click="hapusHarga(n)">
                  <span class="fa fa-trash"></span>
              </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-body">
        <button type="submit" class="btn btn-success float-end">Simpan Harga</button>
    </div>
</div>
</form>