import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ModalPrintComponent } from 'src/app/modals/modal-print/modal-print.component';
import { ModalQuestionsComponent } from 'src/app/modals/modal-questions/modal-questions.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SaInvoiceService } from 'src/app/services/sa/sa-invoice.service';

@Component({
  selector: 'app-sa-invoice-list',
  templateUrl: './sa-invoice-list.component.html',
  styleUrls: ['./sa-invoice-list.component.scss']
})
export class SaInvoiceListComponent implements OnInit {


  @Input() dataList:any;
  @Input() type:string;
  loadingSave=false;
  loadingId:any;
  companyId:any;
  constructor(
    private toast: Toaster,
    private router:Router,
    private modal:NgbModal,
    private authService:AuthenticationService,
    private SaInvoice:SaInvoiceService,

  ) {
    this.companyId=this.authService.currentUserValue.company
   }

  ngOnInit(): void {
    console.log(this.dataList);
  }
  createPayment(invoice_id:string,cid:string,name:string){
    this.router.navigate(['/sa-finance/paid-invoice'],{ queryParams: { invoice_id,cid,name} })
  }
  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }

  voidInvoice(data:any){
    console.log(this.companyId.company_id)
    const modalRef = this.modal.open(ModalQuestionsComponent, {
      size: "sm",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Void";
    modalRef.componentInstance.content = "Invoice ini akan di batalkan, lanjutkan  ?";
    modalRef.result.then(
      (result) => {
        console.log(data);
        if (result) {
         
            this.nowVoid(data);
        } else {
        }
      },
      (reason) => {
        console.log("res", reason);
      }
    );
  };
 
  async nowVoid(data:any){
    this.loadingSave=true;
    this.loadingId=data.id;
    const res: any = await this.SaInvoice.voidInvoice(this.companyId.company_id,data.id);
    console.log(res);
    if (res.success) {
      this.loadingSave = false;
      this.toast.open({ type: "success", text: "Invoice berhasil di void" });
    } else {
      this.loadingSave = false;
      return this.toast.open({ type: "danger", text: res.err });
    }

  }
  openPrintModal(typePrint:string,id:string){
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      format:typePrint,
      id,
      report:'INVOICE'
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }

  gotoPage(invoice:any){

  }

}
